import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import * as Common from '../Common';
import * as Constants from '../Constants';

class Admin extends React.Component {
  constructor(props) {
    super();

    this.state = {
      error: false,
      isLoaded: false,
      runDate: null,
      runTime: null,
      drivers: null,
      lapTimes: [],
      selectedKart: null,
      kartLabels: [],
    };

    this.setDropdown = this.setDropdown.bind(this);
    this.addEntry = this.addEntry.bind(this);
  }

  async componentDidMount() {
    const sessionData = await Common.FetchGet(`${Constants.SERVER_ADDRESS}/api/speedway/GetSessionData/${this.props.match.params.sessionId}`);
    const sessionId = this.props.match.params.sessionId ? this.props.match.params.sessionId : await Common.FetchGet(`${Constants.SERVER_ADDRESS}/api/speedway/GetCurrentSessionId`);
    const drivers = await Common.FetchGet(`${Constants.SERVER_ADDRESS}/db/GetTableEntries/Drivers`);

    setBestIndex(sessionData.LapTimes);

    if(sessionData === 'No Data'){
      this.setState({
        error: "Session Expired."
      });
    } else {
      this.setState({
        isLoaded: true,
        runDate: sessionData.Date,
        runTime: sessionData.Time,
        lapTimes: sessionData.LapTimes,
        sessionTitle: sessionData.SessionTitle,
        drivers: drivers,
        sessionId: sessionId,
        kartLabels: sessionData.LapTimes.map((kart) => kart.label)
      });
    }
  }

  setDropdown(driver, kartIndex){
    let lapTimes = this.state.lapTimes;
    lapTimes[kartIndex].ActiveDriver = { Name:driver.Name, Id: driver.DriverID };

    this.setState({
      lapTimes: lapTimes
    });
  }

  async addEntry(kartIndex){
    let url = `${Constants.ADMIN_SERVER_ADDRESS}/api/admin/postmapping`;
    let postBody =  {
      SessionDate : this.state.runDate,
      RunTime: this.state.runTime,
      DriverID: this.state.lapTimes[kartIndex].ActiveDriver.Id,
      KartNo: this.state.lapTimes[kartIndex].label.replace(/\D/g,''),
      SessionId: this.state.sessionId
    };
    
    await Common.FetchPost(url, postBody);

    let lapTimes = this.state.lapTimes;
    lapTimes[kartIndex].Added = true;

    this.setState({
      lapTimes: lapTimes
    });
  }

  render() {
    let { error } = this.state;
    const {
      isLoaded,
      lapTimes,
      runDate,
      runTime,
      drivers,
      sessionTitle,
      kartLabels,
      selectedKart,
    } = this.state;


    if(!lapTimes){
      error = "No data available";
    }

    if (error) {
      return <div>{error}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <div>
          <h1>{runDate} | {runTime} | {sessionTitle && sessionTitle}</h1>
          <div className="speedwaydata-table">
            <div className="kart-list">
              {kartLabels.map((kart, kartIndex) => (
                <div
                  className={`kart-label ${selectedKart === kartIndex && 'active'}`}
                  key={kartIndex}
                  onClick={() => this.setState({selectedKart: kartIndex})}
                >
                  {kart}
                </div>
              ))}
            </div>
            <div className="kart-data">
              <div className="lap-times">
                {selectedKart !== null && lapTimes[selectedKart].data.map((lapTime, lapIndex) => (
                  <div
                    className={`lap-time ${(lapTimes[selectedKart].fastest === lapIndex ? ('fastest') : (lapTimes[selectedKart].second === lapIndex && 'second'))}`}
                    key={lapIndex}
                  >
                    <div className="lap-number">
                      {lapIndex + 1}
                    </div>
                    <div className="time">
                      {Common.GetDisplayStringTime(lapTime)}
                    </div>
                  </div>
                ))}
              </div>
              {selectedKart !== null &&
                <div className="action-holder">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                    >
                      {lapTimes[selectedKart].ActiveDriver?.Name ? lapTimes[selectedKart].ActiveDriver.Name : "Driver..."}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {drivers.map((driver) =>
                        <Dropdown.Item
                          className={lapTimes[selectedKart].ActiveDriver?.Name === driver.Name && 'active'}
                          key={driver.DriverID}
                          onClick={() => this.setDropdown(driver, selectedKart)}
                        >
                          {driver.Name}
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                    <Button
                      variant="primary"
                      disabled={lapTimes[selectedKart].Added}
                      onClick={() => this.addEntry(selectedKart)}
                    >
                      {this.state.isLoading ? 'Loading…' : 'Add'}
                    </Button>
                </div>
              }
            </div>
          </div>
        </div>
      );
    }
  }
}

function setBestIndex(lapTimes) {
  if(lapTimes){
    lapTimes.forEach(run => {
      if (run.data && run.data.length > 0) {
        var fastest = Math.min.apply(null, run.data);
        var second = secondMax(run.data);
  
        run.fastest = run.data.findIndex((element) => element === fastest);
        run.second = run.data.findIndex((element) => element === second);
      }
    });
  }
};

function secondMax(arr) {
  var max = Math.min.apply(null, arr), // get the max of the array
    maxi = arr.indexOf(max);
  arr[maxi] = 999999; // replace max in the array with -infinity
  var secondMax = Math.min.apply(null, arr); // get the new max 
  arr[maxi] = max;
  return secondMax;
};

export default Admin