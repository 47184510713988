import React from 'react';
import * as Constants from '../Constants';
import * as Common from '../Common';
import { Tab, Nav} from 'react-bootstrap/';
import ResultsTable from './ResultsTable';

// const images = importAll(require.context('./images/tracks/', false, /\.(png|jpe?g|svg)$/));

var parse = require('html-react-parser');

class Tracks extends React.Component {
  constructor(props) {
    super();

    this.state = {
      error: null,
      isLoaded: true,
      tracks: []
    };
  }

  componentDidMount() {
    fetch(Constants.SERVER_ADDRESS + "/db/GetTableEntries/Locations")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            tracks: result
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  }

  loadTrackResults(trackId) {
    fetch(Constants.SERVER_ADDRESS + "/api/TrackBest/" + trackId)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            results: result[0]
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  loadRace(sessionId) {
    fetch(Constants.SERVER_ADDRESS + "/api/Races/" + sessionId)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            results: result[0]
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render() {
    let { error, isLoaded, tracks, results } = this.state;
    let resultstable;

    if (results && results.length > 0) {
      resultstable = parse(Common.DrawStandings(results));
    }

    if (this.state)
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <div>Loading...</div>;
      } else {
        return (
          <div>
            <Tab.Container defaultActiveKey="speedway">
              <Nav className="trackTabs" variant="tabs">
                <Nav.Item>
                  <Nav.Link className="trackTabTitle" eventKey="speedway">Speedway</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className="trackTabTitle" eventKey="other">Other</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="speedway">
                  <div className="squareGrid">
                    {tracks.filter(track => track.Host.toLowerCase().includes('speedway')).sort((a, b) => a.Configuration > b.Configuration ? 1 : -1).map((track, key) => (
                      <a key={key} onClick={() => this.loadTrackResults(track.LocationID)} className="trackSquare" target="_blank">
                        <p className="trackSquareTitle">{track.Configuration}</p>
                        <div className="item" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/tracks/${track.TrackMap ? track.TrackMap : 'C00'}.png` }}></div>
                      </a>
                    )
                    )}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="other">
                  <div className="squareGrid">
                    {tracks.filter(track => !track.Host.toLowerCase().includes('speedway')).map((track, key) => (

                      <a className="trackSquare" key={key} onClick={() => this.loadTrackResults(track.LocationID)} target="_blank">
                        <p className="trackSquareTitle">{track.Host}</p>
                        <div className="item" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/tracks/${track.TrackMap ? track.TrackMap : 'C00'}.png` }}></div>
                      </a>
                    )
                    )}
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
            <ResultsTable results={results} />
          </div>
        );
      }
  }
}

export default Tracks