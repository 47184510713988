import React from 'react';
import { BrowserRouter as Router, Route} from "react-router-dom";
import './css/App.css';
import Sessions from './Components/Sessions';
import Home from './Components/Home';
import Tracks from './Components/Tracks';
import Admin from './Components/Admin';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import QueueMap from './Components/QueueMap';
import Auth from './Components/Auth';
import { FetchGet } from './Common';
import * as Constants from './Constants';
import Chart from './Components/Chart';
const token = localStorage.getItem('karts-access-token');
const isValid = token ? FetchGet(`${Constants.SERVER_ADDRESS}/auth/validatetoken/`) : null;


function App() {

  let administrationNav;
  let administrationRoutes;

  if(isValid){
    administrationNav = (
        <Nav.Link href="/web/admin/queuemap">Queue Map</Nav.Link>
      );
    administrationRoutes = (
      <Route exact path="/web/chart/" component={Chart} />
      );
  }else{
    administrationNav = (
      <Nav.Link href="/web/auth">Auth</Nav.Link>
    );

    administrationRoutes = (
      <Route exact path="/web/auth/" component={Auth} />
    );
  }

  return (
    <div className="App">
      <Navbar bg="dark" variant="dark">
        <Navbar.Brand href="/">K-Bois</Navbar.Brand>
        <Nav className="mr-auto">
          <Nav.Link href="/web/sessions">Sessions</Nav.Link>
          <Nav.Link href="/web/tracks">Tracks</Nav.Link>
          <Nav.Link href="/web/chart">Chart</Nav.Link>
          {administrationNav}
        </Nav>
      </Navbar>
      
      <Router>
        <Route exact path="/" component={Home} />
        <Route exact path="/web/sessions" component={Sessions} />
        <Route exact path="/web/tracks" component={Tracks} />
        <Route exact path="/web/admin/queuemap" component={QueueMap} />
        <Route exact path="/web/admin/session" component={Admin} />
        <Route exact path="/web/admin/session/:sessionId" component={Admin} />
        {administrationRoutes}
      </Router>
    </div>
  );
}

export default App;
