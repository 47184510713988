import React from 'react';
import { GetDifference } from '../Common';

class ResultsTable extends React.Component {

  formatLapTime(lapTime) {
    return lapTime.split(':').slice(1).join(':');
  }

  render() {
    if (this.props.results?.length > 0) {
      return (
        <div className="results-table">
          <div className="headings">
            <div className="heading">Driver (Kart)</div>
            <div className="heading">Lap Time</div>
            <div className="heading">Next Driver Difference</div>
            <div className="heading">Pole Difference</div>
          </div>
          <div className="rows">
            {this.props.results.map((result, index) => (
              <div key={index} className="row">
                <div className="cell">{result.Name + ' (' + result.Kart + ')'}</div>
                <div className="cell">{Number(this.formatLapTime(result.LapTime).split(':')[0].replace('0','')) > 3 ? '--:--:---' : this.formatLapTime(result.LapTime)}</div>
                <div className="cell">{index === 0 ? 'Interval' : GetDifference(this.props.results[index - 1].LapTime, result.LapTime)}</div>
                <div className="cell">{index === 0 ? 'Interval' : GetDifference(this.props.results[0].LapTime, result.LapTime)}</div>
              </div>
            ))}
          </div>
        </div>
      );
    }
    return (
      <div />
    );
  }
};

export default ResultsTable;
