import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { FetchPost, FetchGet } from '../Common';
import * as Constants from '../Constants';
import QrReader from 'react-qr-reader';

class QueueMap extends React.Component {
  constructor(props) {
    super();

    this.state = {
      url: null,
      location: null,
      locations: [],
      queues: [],
      error: null,
      showScanner: false
    }

    this.setUrl = this.setUrl.bind(this);
    this.setDropdown = this.setDropdown.bind(this);
  }

  async componentDidMount() {
    const locations = await FetchGet(`${Constants.SERVER_ADDRESS}/db/GetTableEntries/Locations/`);
    const queues = await FetchGet(`${Constants.SERVER_ADDRESS}/db/GetTableEntries/Queues/`);

    if (locations?.code == "ER_CON_COUNT_ERROR" || queues?.code == "ER_CON_COUNT_ERROR") {
      this.setState({
        error: "Too many connections"
      });
    } else if(queues == 401){
      this.setState({
        error: "Unauthorized"
      });
    } else {
      if( locations && queues){
        this.setState({
          locations: locations,
          queues: queues //.filter(queue => queue.Status == 'PENDING')
        });
      }else{
        this.setState({
          error: "Sum Ting Wong."
        });
      }
    }
  }

  handleScan = data => {
    if (data) {
      this.setState({
        url: data,
        showScanner: false
      })
    }
  }

  handleError = err => {
    console.error(err)
  }

  async setUrl(url) {
    if (!url) {
      url = await FetchGet(`${Constants.SERVER_ADDRESS}/api/speedway/GetCurrentSessionLink`);
    }

    this.setState({
      url: url
    });
  };
  
  async showScanner() {
    this.setState({
      showScanner: true,
      url: null
    });
  };

  setDropdown(location) {
    this.setState({
      location: location
    });
  };

  async postQueue(sessionURL, location) {
    let url = `${Constants.ADMIN_SERVER_ADDRESS}/api/admin/postqueue`;
    let postBody = {
      URL: sessionURL,
      Location: location.LocationID
    }

    await FetchPost(url, postBody);
    const queues = await FetchGet(`${Constants.SERVER_ADDRESS}/db/GetTableEntries/Queues/`);

    this.setState({
      queues: queues //.filter(queue => queue.Status == 'PENDING')
    });
  }

  render() {
    let { locations, location, queues, url, error, showScanner } = this.state;
    let mainState = this;
    return (
      <div>
        {error ? (<h1>{error}</h1>) : [
          (showScanner ? (
            <QrReader
              delay={300}
              onError={this.handleError}
              onScan={this.handleScan}
              style={{ width: '100%' }}
            />
            ) :
          (
            <div>
              <br></br>
              <h1>Add Queues Here</h1>
              <Form>
                <Form.Group>
                  <Form.Label>QR URL</Form.Label>
                  <Form.Control type="URL" placeholder="Paste QR URL" onChange={(event) => { mainState.setState({ url: event.target.value }); }} defaultValue={this.state.url ? this.state.url : ""} />
                </Form.Group>
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic"> {location ? `${location.Host} - ${location.Configuration}` : "Location..."} </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {locations.map(
                      (locationListItem, locationIndex) => (
                        <Dropdown.Item 
                          className={location?.LocationID === locationListItem.LocationID ? 'active' : ''} 
                          key={locationIndex} 
                          onClick={() => mainState.setDropdown(locationListItem)} >{locationListItem.Host} - {locationListItem.Configuration}
                        </Dropdown.Item>
                      )
                    )}
                  </Dropdown.Menu>
                </Dropdown>
                <Button variant="primary" onClick={() => { mainState.setUrl(null) }}> Set to current </Button>
                <Button variant="primary" onClick={() => { mainState.showScanner() }}> Scan QR </Button>
                <Button variant="primary" disabled={!location} onClick={() => { mainState.postQueue(url, location) }} > Submit </Button>
              </Form>
              <h1>Current Queues</h1>
              <div className="queue-table">
                    <div className="headings">
                      <div className="heading">Speedway</div>
                      <div className="heading">Admin</div>
                      <div className="heading">Location</div>
                      <div className="heading">Status</div>
                    </div>
                    <div className="queue-rows">
                      {queues.map((queue, queueIndex) => (
                        <div key={queueIndex} className="row">
                          <div className="cell"><a target="_blank" rel="noopener noreferrer" href={queue.URL}>Speedway</a></div>
                          <div className="cell"><a target="_blank" rel="noopener noreferrer" href={`${Constants.ADMIN_CLIENT_ADDRESS}/web/admin/session/${queue.URL.split('?')[1]}`}>Admin</a></div>
                          <div className="cell">{`${locations.find(loc => loc.LocationID === queue.Location).Host} - ${locations.find(loc => loc.LocationID === queue.Location).Configuration}`}</div>
                          <div className="cell">{queue.Status}</div>
                        </div>
                      ))}
                    </div>
              </div>
            </div>
          )
          )
        ]}
      </div>
    );
  }
}

export default QueueMap