export const SERVER_ADDRESS = "https://karts.theamazingtom.com";
// export const SERVER_ADDRESS = "http://localhost:3002";

export const CLIENT_ADDRESS = "https://karts.theamazingtom.com";
// export const CLIENT_ADDRESS = "http://localhost:3000";

export const ADMIN_CLIENT_ADDRESS = "https://karts.theamazingtom.com";
// export const ADMIN_CLIENT_ADDRESS = "http://localhost:3000";

export const ADMIN_SERVER_ADDRESS = "https://karts.theamazingtom.com";
// export const ADMIN_SERVER_ADDRESS = "http://localhost:3002";

export const DEFAULT_HEADERS = {
    'Content-Type': 'application/json',
    'karts-access-token': localStorage.getItem('karts-access-token')
};