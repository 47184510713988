import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import * as Constants from '../Constants';
import { FetchGet } from '../Common';

class Auth extends React.Component {
  constructor(props) {
    super();

    this.state = {
      password : null,
      error : " "
    }
  }

  async componentDidMount() {
    let token = localStorage.getItem('karts-access-token');
    if(token){
      let result = await FetchGet(`${Constants.SERVER_ADDRESS}/auth/validatetoken`);
      if (result) {
        this.setState({
          error: "Success!",
        });
      }
    }else{
      this.setState({
        error: null,
      });
    }
  }

  // function Paebat(){
  //   // Send in state PW
  //   //Execute the code
  // }

  render() {
    let { error } = this.state;
    let mainState = this;
    return (
      <div>
        {error ? (<h1>{error}</h1>) : (
          <div>
            <br></br>
            <Form>
              <Form.Group>
                <Form.Label>Password</Form.Label>
                <Form.Control type="URL" placeholder="Enter password" onChange={(event) => { mainState.setState({ password: event.target.value }); }} defaultValue={this.state.password ? this.state.password : ""} />
              </Form.Group>
              <Button variant="primary" onClick={() => {
                  if (this.state.password && this.state.password != 'clear'){
                    let token = localStorage.getItem('karts-access-token');
                    if (!token) {
                      fetch(`${Constants.SERVER_ADDRESS}/auth/gettoken`, { headers: { 'Authorization': `Basic ${Buffer.from(this.state.password, 'utf-8').toString('base64')}` }, })
                        .then(res => res.json())
                        .then(
                          (result) => {
                            if (result == '401 Unauthorized'){
                              localStorage.clear();
                              this.setState({
                                error: "401 Unauthorized.",
                              });
                            }else{
                              var token = result.token;
                              if (token){
                                localStorage.setItem('karts-access-token', token);
                                this.setState({
                                  error: "Success.",
                                });
                              }
                            }
                          },
                          (error) => {
                            console.log(error);
                            // Unhandled
                          }
                        );
                    }
                  } else if (this.state.password == 'clear'){
                    localStorage.clear();
                  }
                }
                } > GetToken </Button>
            </Form>
          </div>
        )}
      </div>
    );
  }
}

export default Auth