import { DEFAULT_HEADERS } from './Constants';

export function GetDisplayStringTime(time) {
    //73.421997070312
    if(time == 0){
      return "--:--:---";
    }

    var minutes = Math.trunc(time / 60);
    var seconds = Math.trunc(time % 60);
    var ms = Math.round(time % 1 * 1000);

    if (Math.trunc(time / 60) < 10) {
      minutes = "0" + minutes;
    }

    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    if (Math.round(time % 1 * 1000) < 10) {
      ms = "00" + ms;
    } else if (ms < 100) {
      ms = "0" + ms;
    }

    if(minutes > 3){
      return "--:--:---";
    } else{
      return minutes + ":" + seconds + ":" + ms;
    }
  }

export function GetDifference(firstTime, secondTime) {
    var first = firstTime.split(':').slice(1).join(':').replace('.',':').split(':');
    var second = secondTime.split(':').slice(1).join(':').replace('.',':').split(':');

    try{
      if (first.length > 1 && second.length > 1) {

        let firstMillis = Number(first[0]) * 60000 + Number(first[1]) * 1000 + Number(first[2]);
        let secondMillis = Number(second[0]) * 60000 + Number(second[1]) * 1000 + Number(second[2]);
  
        var differenceMillis = secondMillis - firstMillis;
  
        var diffSeconds = differenceMillis / 1000;
        //var diffMillis = differenceMillis % 1000;

        if(diffSeconds > 120){
          return 'N/A';
        }
  
        return `${diffSeconds.toFixed(3)}s`;
      } else if (secondTime === 'DNF') {
        return 'DNF';
      } else {
        return 'DNS';
      }
    }catch{
      return 'N/A';
    }
  };

  export function DrawStandings(standings) {
    var raceString = '<table>';
    raceString += '<tr>';
    raceString += '<th class="table-heading">Driver</th>';
    raceString += '<th class="table-heading">Lap Time</th>';
    raceString += '<th class="table-heading">Next Driver Difference</th>';
    raceString += '<th class="table-heading">Pole Difference</th>';
    // raceString += '<th>Kart</th>';
    raceString += '</tr>';

    for (var driver = 0; driver < standings.length; driver++) {
      raceString += '<tr class="standings-row">';
      raceString += '<td>' + standings[driver].Name + '</td>';

      let lapTime = standings[driver].LapTime.split(':').slice(1).join(':');

      if(Number(lapTime.split(':')[0].replace('0','')) > 3){
        raceString += '<td>--:--:---</td>';
      }else{
        raceString += '<td>' + lapTime + '</td>';
      }

      if (driver === 0) {
        raceString += '<td>Interval</td>';
      } else {
        raceString += '<td>' + GetDifference(standings[driver - 1].LapTime, standings[driver].LapTime) + '</td>';
      }
      if (driver === 0) {
        raceString += '<td>Interval</td>';
      } else {
        raceString += '<td>' + GetDifference(standings[0].LapTime, standings[driver].LapTime) + '</td>';
      }

      // raceString += '<td>' + standings[driver].Kart + '</td>';
      raceString += '</tr>';
    }

    raceString += '</table>';

    return raceString;
  };

  export async function FetchGet(url) {
    let data = await (fetch(url, { headers: DEFAULT_HEADERS} )
      .then(res => {
			  return res.json();
		  }).catch(err => {
			  console.log('Error: ', err)
		})
	);
	return data
}

export async function GetSessions(){

}

export async function FetchPost(url = '', body) {

    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    //   credentials: 'same-origin', // include, *same-origin, omit
      headers: DEFAULT_HEADERS,
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(body)
    });
    return response.json();
  }
  
//   msToTime(duration) {
//     var milliseconds = parseInt((duration % 1000)),
//       seconds = Math.floor((duration / 1000) % 60),
//       minutes = Math.floor((duration / (1000 * 60)) % 60),
//       hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

//     hours = (hours < 10) ? "0" + hours : hours;
//     minutes = (minutes < 10) ? "0" + minutes : minutes;
//     seconds = (seconds < 10) ? "0" + seconds : seconds;

//     return ((hours == "00") ? "" : hours + ":") + (minutes == "00" ? "" : minutes + ":") + seconds + "." + (milliseconds < 100 ? "0" + milliseconds : milliseconds);
//   }

//   timeToMs(time) {
//     time = time.split(':');

//     var h = Number(time[0]);
//     var m = Number(time[1]);
//     var s = Number(time[2].split('.')[0]);
//     var ms = Number(time[2].split('.')[1]);

//     return Number((h * 60 * 60 + m * 60 + s) * 1000) + ms;
//   }