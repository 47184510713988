import React from 'react';
import * as Constants from '../Constants';
import { FetchGet } from '../Common';

class Chart extends React.Component {
    constructor(props) {
        super();

        this.state = {
            data: null,
            error: null
        }
    }

    async componentDidMount() {
        let token = localStorage.getItem('karts-access-token');
        if (token) {
            let result = await FetchGet(`https://karts.theamazingtom.com/api/Sessions/1`);
            if (result) {
                this.setState({
                    data: result,
                });
            }
        } else {
            this.setState({
                error: null,
            });
        }
    }

    render() {
        let { error } = this.state;
        let mainState = this;
        return (
            <div>
                {error ? (<h1>{error}</h1>) : (
                    <div>
                        Hello
                    </div>
                )}
            </div>
        );
    }
}

export default Chart