import React from 'react';
import * as Constants from '../Constants';

// Copy pasta from StackOverflow
(function () {
  if (typeof Object.defineProperty === 'function') {
    try { Object.defineProperty(Array.prototype, 'sortBy', { value: sb }); } catch (e) { }
  }
  if (!Array.prototype.sortBy) Array.prototype.sortBy = sb;

  function sb(f) {
    for (var i = this.length; i;) {
      var o = this[--i];
      this[i] = [].concat(f.call(o, o, i), o);
    }
    this.sort(function (a, b) {
      for (var i = 0, len = a.length; i < len; ++i) {
        if (a[i] != b[i]) return a[i] < b[i] ? -1 : 1;
      }
      return 0;
    });
    for (var i = this.length; i;) {
      this[--i] = this[i][this[i].length - 1];
    }
    return this;
  }
})();

class Home extends React.Component {
  constructor(props) {
    super();

    this.state = {
      sessions: []
    };
  }

  componentDidMount() {
    fetch(Constants.SERVER_ADDRESS + "/db/GetTableEntries/Sessions")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            sessions: result
          });
          let that = this;
          result.forEach(session => {
            fetch(Constants.SERVER_ADDRESS + "/api/GetSessionWinner/" + session.SessionID)
              .then(res => res.json())
              .then(
                (result) => {
                  var sessionData = result[0];

                  var specificSession = that.state.sessions.filter(stateSession => {
                    return stateSession.SessionID === sessionData.SessionId;
                  })[0];
                  specificSession.Winner = sessionData;

                  that.setState({
                    isLoaded: true,
                    sessions: that.state.sessions
                  });
                }
              )
          });

        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  }

  render() {
    let {sessions: competetiveSessions} = this.state;
    let seasons = [];
    if (competetiveSessions?.length > 0){
      competetiveSessions = competetiveSessions.filter((o)=>o.Competetive).sortBy((o) => o.Date).reverse();

      for (var i = 0; i < competetiveSessions.length; i++) {
        let year = new Date(competetiveSessions[i].Date).getFullYear();
        let season = seasons.filter((season) => season.Year == year)[0];
        if (!season?.Year) {
          seasons.push({ Year: year, Races: [] });
          season = seasons.filter((season) => season.Year == year)[0];
        }

        season.Races.push(competetiveSessions[i]);
      }
    }

    return (
      <div>
        {seasons.map((season, seasonIndex)=>(
          <div key={seasonIndex}>
            <h1>Season {season.Year}</h1>
            <div className="results-table">
              <div className="headings">
                <div className="heading">Date</div>
                <div className="heading">LapTime</div>
                <div className="heading">Track</div>
                <div className="heading">Winner</div>
              </div>
              <div className="rows">
                {season.Races.map((race, raceIndex) => (
                  <div className="row" key={raceIndex}>
                    <div className="cell">{race.Date.split('T')[0]}</div>
                    <div className="cell">{race.Winner?.LapTime}</div>
                    <div className="cell">{race?.Winner?.HostName} ({race?.Winner?.Configuration})</div>
                    <div className="cell">{race?.Winner?.Name}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default Home