import React from 'react';
import * as Constants from '../Constants';
import * as Common from '../Common';
import ResultsTable from './ResultsTable';

var parse = require('html-react-parser');

class Sessions extends React.Component {
  constructor(props) {
    super();

    this.state = {
      error: null,
      isLoaded: false,
      sessions: [],
      results: [],
      tracks: []
    };

    this.loadResults = this.loadResults.bind(this);
  }

  componentDidMount() {
    fetch(Constants.SERVER_ADDRESS + "/db/GetTableEntries/Sessions")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            sessions: result
          });
          let that = this;
          result.forEach(session => {
            fetch(Constants.SERVER_ADDRESS + "/api/GetSessionWinner/" + session.SessionID)
              .then(res => res.json())
              .then(
                (result) => {
                  var sessionData = result[0];

                  var specificSession = that.state.sessions.filter(stateSession => {
                    return stateSession.SessionID === sessionData.SessionId;
                  })[0];
                  specificSession.Winner = sessionData;

                  that.setState({
                    isLoaded: true,
                    sessions: that.state.sessions
                  });
                }
              )
          });

        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  }

  loadResults(sessionId) {
    fetch(Constants.SERVER_ADDRESS + "/api/SessionResults/" + sessionId)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            results: result[0]
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  loadTrackResults(trackId) {
    fetch(Constants.SERVER_ADDRESS + "/api/TrackBest/" + trackId)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            results: result[0]
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  getSessionWinner(sessionId) {
    fetch(Constants.SERVER_ADDRESS + "/api/GetSessionWinner/" + sessionId)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            results: result[0]
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  loadSession(sessionId) {
    fetch(Constants.SERVER_ADDRESS + "/api/Sessions/" + sessionId)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            results: result[0]
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render() {
    let { error, isLoaded, sessions, results } = this.state;
    // sessions  = sessions.filter((a) => new Date(a.Date) > new Date(2021, 1, 1));
    sessions.sort((a, b) => a.Date - b.Date);
    let resultstable;
    var mainState = this;
    if (results && results.length > 0) {
      resultstable = parse(Common.DrawStandings(results));
    }

    if (this.state) {
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <div>Loading...</div>;
      } else {
        return (
          <div>
            <p className="track"><b>SESSIONS</b></p>
            <div className="sessions-wrapper">
              <ul className="session-list">
                {sessions.map(item => (
                  <li
                    className="list-item"
                    key={item.SessionID}
                  >
                    <div className="track-title">
                      {item.Title + " "}
                    </div>
                    <div className="button-wrapper">
                      <button
                        className="btn btn-primary"
                        onClick={() => mainState.loadResults(item.SessionID)}
                      >
                        Results
                      </button>
                      <button
                        className="btn btn-primary"
                        onClick={() => mainState.loadSession(item.SessionID)}
                      >
                        Times
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
              <div className="standings">
                <ResultsTable results={this.state.results} />
              </div>
            </div>
          </div>
        );
      }
    }
  }
}

export default Sessions